
import React, { useState, useEffect }  from 'react';
import {
  Link
} from "react-router-dom";
import * as Config from './../../../Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
import Calendar from '../../calendar';
import PersonnelBlock from './personnelBlock';
import InvitationBlock from './invitationBlock';

function Dir_DashBoardPage(props){
  const [formData, setFormData] = useState({list : null, isLoading : false});
  const onInvitationsChange = function(what, who){
    setFormData({list : null, isLoading : false})
  }
  const loadData = function(){
    setFormData({list : null, isLoading : true});
    fetch(Config.BASE_URL + "structure/list",{
      headers: new Headers({
          'Authorization': 'Bearer ' + props.user.getUserToken, 
      })
    }).then(res => res.json())
    .then(
      (structuresList) => {
        if(structuresList.success && structuresList.res!=null){
          fetch(Config.BASE_URL + "structure_group/list",{
            headers: new Headers({
                'Authorization': 'Bearer ' + props.user.getUserToken, 
            })
          })
          .then(res2 => res2.json())
          .then(
            (structureGroupsList) => {
              fetch(Config.BASE_URL + "personnelInvitation/map",{
                headers: new Headers({
                    'Authorization': 'Bearer ' + props.user.getUserToken, 
                })
              })
              .then(res2 => res2.json())
              .then(
                (personnelInvitationsMap) => {
                  let struttureMap = {};
                    for(var i = 0; i < structuresList.res.length; i++){
                      struttureMap[structuresList.res[i].id] = "" + i;
                      structuresList.res[i].reparti = [];
                    }
                  if(structureGroupsList.success && structureGroupsList.res!=null){
                    for(var i = 0; i < structureGroupsList.res.length; i++){
                      let currStructureGroup = structureGroupsList.res[i];
                      if(typeof(struttureMap[currStructureGroup.id_structure])!== 'undefined'){
                        currStructureGroup["invitations"] = {};
                        if(personnelInvitationsMap.success && personnelInvitationsMap.res!=null && personnelInvitationsMap.res[currStructureGroup.id_structure]
                          && personnelInvitationsMap.res[currStructureGroup.id_structure][currStructureGroup.id]){
                            var target = personnelInvitationsMap.res[currStructureGroup.id_structure][currStructureGroup.id]
                            Object.keys(target).forEach(element => {
                              currStructureGroup["invitations"][element] = target[element].filter(singleInvitation => {
                                return singleInvitation.ca == 0 && singleInvitation.co == 0})
                            })
                        }
                        structuresList.res[struttureMap[currStructureGroup.id_structure]].reparti.push(currStructureGroup)
                      }
                    }
                  }
                  console.log(structuresList.res)
                  setFormData({list : structuresList.res, isLoading : false});
                  return;
              });
            }
          )
        }
      }
    )
  }
  useEffect(()=>{
    if(formData.list==null && !formData.isLoading){
      loadData()
    }
  }, [formData])
  return (
    formData.list == null ? (<h6 className='text-center mt-4'>Caricamento...</h6>) : (
      formData.list.length>0 ? (
    <>
      <div class="accordion accordion-dark mt-3">
        {
          formData.list.map(item => (
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button fs-5" type="button" data-bs-toggle="collapse" data-bs-target={"#collapsibleStructure" + item.id} aria-expanded="true" aria-controls="collapseOne" disabled = {formData.list.length == 1 ? "disabled" : ""}>
                  {item.name}
                </button>
              </h2>
              <div id={"collapsibleStructure" + item.id} class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="alert alert-secondary text-center" role="alert">
                  {
                    item.reparti.map(unReparto => (
                      <div class="card">
                        <div class="card-body py-3">
                        <Link className="card-title text-primary fs-5 px-3" to={"/modificaReparto/" + item.id + "/" + unReparto.id}>{unReparto.name}&nbsp;&nbsp;<FontAwesomeIcon icon={faEdit}/></Link>
                        </div>
                        <div class="card-footer p-3">
                          { (unReparto.workers[20] && unReparto.workers[20].length>0) ?
                            (unReparto.workers[20].map(unWorker => (
                              <PersonnelBlock worker={unWorker} user={props.user}/>
                            ))) : ((unReparto.invitations && unReparto.invitations[20] && unReparto.invitations[20].length>0) ? 
                            (<InvitationBlock invitation={unReparto.invitations[20][0]} user={props.user} onInvitationsChange={onInvitationsChange}/>):
                            (<Link className="btn btn-primary d-block" to={"/invitaCaporeparto/" + unReparto.id}>Aggiungi Caporeparto</Link>))
                          }
                          <div class="border-bottom mb-3 mt-3"></div>
                          { (unReparto.invitations && unReparto.invitations[1] && unReparto.invitations[1].length>0 
                            && unReparto.invitations[1].map(unInvitation => (
                              <InvitationBlock invitation={unInvitation} user={props.user} onInvitationsChange={onInvitationsChange}/>
                            )))
                          }
                          <Link className="btn btn-success d-block" to={"/invitaPersonale/" + unReparto.id}>Aggiungi Personale</Link>
                          { (unReparto.workers[1] && unReparto.workers[1].length>0 
                            && unReparto.workers[1].map(unWorker => (
                              <PersonnelBlock worker={unWorker} user={props.user}/>
                            )))
                          }
                        </div>
                      </div>
                    ))
                  }
                  {(item.reparti==null || item.reparti.length==0) && <>Nessun reparto definito</>}
                  </div>
                  <Link className="btn btn-primary" to={"/creaReparto/" + item.id}>Aggiungi Reparto</Link>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </>) : (<h6 className='text-center mt-4'>Nessuna struttura collegata, contattare il supporto</h6>))
  );
}

export default Dir_DashBoardPage;