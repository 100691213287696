
import React, { useEffect, useState } from 'react';
import * as Config from '../Config'
import CrudManager from './CrudManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Link
} from "react-router-dom";
import { faLocation } from '@fortawesome/free-solid-svg-icons';

export default class CrudManagerBuilder extends React.Component {

    constructor(props) {
      super(props);
    }
  
    render() {
      var config = null
      switch(this.props.entity){
        case "structure":
          config = {entity: "structure", entityName : "Struttura", canCreate : this.props.user.hasCapability("create_structures"), columns : [
            {hidden : true, isId : true,  field : "id"},
            {label : "Nome", field : "name"},
            {label : "Indirizzo", field : "address"},
            {label : "", custom : (rowData)=>{
              return (<td onClick={()=>{window.open("http://maps.apple.com/?ll=" + rowData["lat"] + "," + rowData["lon"], '_blank').focus();}}><FontAwesomeIcon icon={faLocation}/> Posizione</td>)
            }},
            {hidden : !this.props.user.hasCapability("assign_group_leader"), label : "Dirigente", custom : (rowData)=>{
              return rowData["managment"] && rowData["managment"].length>0 ? (<td>{rowData["managment"][0]["name"]} {rowData["managment"][0]["surname"]}</td>) : (<td><Link to={"/structures/assignManager/" + rowData["id"]}>ASSEGNA</Link></td>)
            }},
            {label : "", isEdit : true},
            {label : "", isDelete : true},
          ], fields : ["name", "piva", "address", "lat", "lon"]}
          break;
        case "structure_group":
          config = {entity: "structure_group", entityName : "Gruppo", canCreate : this.props.user.hasCapability("create_structure_groups"), columns : [
            {hidden : true, isId : true,  field : "id"},
            {label : "Nome", field : "name"},
            {label : "", isEdit : true},
            {label : "", isDelete : true},
          ], fields : ["name"]}
          break;
      }
      if(config!=null){
        return (
          <CrudManager user={this.props.user} config={config}/>)
      }
      return (<h2>missing config</h2>)
    }
  }
  