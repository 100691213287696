


import React from 'react';
import {
  Link,
  useParams,
} from "react-router-dom";
import * as Config from './../../../Config'

export default function AssignManager(){
    let { id } = useParams();
    return (
        <div>
          <h3>ID: {id}</h3>
        </div>
      );
}