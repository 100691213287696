
import React, { useState, useEffect }  from 'react';
import {
  Link
} from "react-router-dom";
import * as Config from './../../../Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import Calendar from '../../calendar';

function Group_DashBoardPage (props)  {
  const [formData, setFormData] = useState({list : null, isLoading : false});
  const loadData = function(){
    setFormData({list : null, isLoading : true});
    fetch(Config.BASE_URL + "structure/list",{
      headers: new Headers({
          'Authorization': 'Bearer ' + props.user.getUserToken, 
      })
    }).then(res => res.json())
    .then(
      (structuresList) => {
        if(structuresList.success && structuresList.res!=null){
          fetch(Config.BASE_URL + "structure_group/list",{
            headers: new Headers({
                'Authorization': 'Bearer ' + props.user.getUserToken, 
            })
          })
          .then(res2 => res2.json())
          .then(
            (structureGroupsList) => {              
              setFormData({data : structureGroupsList.res[0] ? { structure : structuresList.res[0], structureGroup : structureGroupsList.res[0]} : null, isLoading : false})
            }
          )
        }
      }
    )
  }
  useEffect(()=>{
    if(formData.data==null && !formData.isLoading){
      loadData()
    }
  }, [formData])
  return (
    formData.data == null ? (<h6 className='text-center mt-4'>Caricamento...</h6>) : (
      formData.data!=null ? (
      <>
        <div class="container-fluid">
          <div class="card row p-3 mt-4">
            <h1 class="h2 mt-2 text-primary">{formData.data.structureGroup.name}</h1>
            <h5 class="mb-4">{formData.data.structure.name}</h5>
            <div class="col border-top pt-4">
              <Calendar startDate={new Date()} structureGroupsData={formData.data}></Calendar>
            </div>
          </div>
        </div>
      </>) : (<h6 className='text-center mt-4'>Nessuna struttura collegata, contattare il supporto</h6>))
  );
}

export default Group_DashBoardPage;