
import React, { useEffect, useState } from 'react';
import * as Config from './../../Config'

const LoadUser = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(()=>{
    if(!isLoading){
      console.log("settingIsLoading")
      setIsLoading(true)
      fetch(Config.BASE_URL + "user/details",{
        headers: new Headers({
            'Authorization': 'Bearer ' + props.getUserToken, 
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            
            if(result.success && result.res!=null){
              console.log("LoadUser > onUserReady")
                props.onUserReady(result.res)
                return;
            }
            props.onFail();
            
          },
          // Nota: è importante gestire gli errori qui
          // invece di un blocco catch() in modo da non fare passare
          // eccezioni da bug reali nei componenti.
          (error) => {
            props.onFail();
          }
        )
    }
  }, [isLoading])
  return (<h6>Loading...</h6>)
}
export default LoadUser;