
import React, {useState}  from 'react';
import * as Config from '../../../Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';

function PersonnelBlock(props){
    return (
        <div class="alert alert-secondary border row mx-2 mt-3" role="alert">
            <div class="col">
                <b class="fs-6">{props.worker.name} {props.worker.surname}</b> {props.worker.role.name}
            </div>
        </div>
    );
}

export default PersonnelBlock;