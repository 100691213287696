
import React, {useState}  from 'react';
import * as Config from '../../../Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';

function InvitationBlock(props){
    const [formData, setFormData] = useState({error : null});
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(window.confirm("Procedere con l'annullamento dell'invito?")){
            try {
                let postData = new FormData();
                postData.append("id", props.invitation.id);
                fetch(Config.BASE_URL + "personnelInvitation/cancel/",{
                    method: "POST",
                    body: postData,
                    headers: new Headers({
                        'Authorization': 'Bearer ' + props.user.getUserToken, 
                    })
                  }).then(res => res.json())
                  .then(
                    (resJson) => {
                        if(resJson.success){
                            props.onInvitationsChange("deleted", props.invitation.id)
                        }else{
                            alert("Impossibile procedere, riprovare più tardi")
                        }
                    });
            } catch (err) {
                console.log(err)
                alert("Impossibile procedere, riprovare più tardi")
            }
        }
    }
    return (
        <div class="alert alert-danger border row mx-2" role="alert">
            <div class="col">
                <b class="fs-6">Invito in sospeso per {props.invitation.e}</b>
                {props.invitation.s != -1 && (<b></b>)}
                <br/>
                Del <Moment format="DD MMMM YYYY" locale="it">{props.invitation.d}</Moment>
            </div>
            <div class="col-auto pt-1"><button class="btn btn-danger" onClick={handleSubmit}>Annulla&nbsp;&nbsp;<FontAwesomeIcon icon={faCancel}/></button></div>
        </div>
    );
}

export default InvitationBlock;