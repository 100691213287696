
import React, { useState, useEffect } from 'react';
import * as Config from '../../Config';
import { Link, Navigate, useParams } from 'react-router-dom';
import {SpecsOptions, getRuoloName} from '../form/specsOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function RegisterPage() {
  const params = useParams();
  const [state, setState] = useState({
    token_data : null,
    reg_email: '',
    reg_email2: '',
    reg_password: '',
    reg_name: '',
    reg_surname: '',
    reg_qualifica: -1,
    success: false,
    error: null,
    errorFields: [],
    city: {
      text: '',
      suggestions: null,
      loading: false,
      value: null,
    },
  });
  useEffect(() => {
    // componentDidMount and componentDidUpdate
    if(params && params.registrationToken && state.token_data==null){
      setState({
        ...state,
        token_data : {
          loading : true,
        }
      })
      let postData = new FormData();
      postData.append("token", params.registrationToken);
      fetch(Config.BASE_URL + "user/getRegistrationData/", {
          method: "POST",
          body: postData,
      }).then(res => res.json())
      .then((res) => {
          console.log(res)
          if(res.success){
            setState({...state, 
              reg_email : res.res.email,
              reg_email2 : res.res.email,
              reg_qualifica : res.res.spec,
              idReparto : res.res.id ,
              token_data : {
                ...res.res,
                loading : false, 
              }
            });
          }else{
            setState({...state, 
              error : true
            })
          }
      });
    }
  }, []); // Only run the effect once

  const handleCityBlur = (e) => {
    if (state.city.value == null) {
      setState({
        ...state,
        city: {
          text: '',
          suggestions: null,
          loading: false,
          value: null,
        },
      });
    }
  };

  const handleCityChange = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({
      ...state,
      city: {
        text: e.target.value,
        suggestions: null,
        loading: true,
        value: null,
      },
    });
    try {
      const formData = new FormData();
      formData.append('q', e.target.value);
      const res = await fetch(`${Config.BASE_URL}cities/search/`, {
        method: 'POST',
        body: formData,
      });
      const resJson = await res.json();
      let suggestions = null;
      if (res.status === 200 && resJson.success) {
        suggestions = resJson.res;
      }
      setState({
        ...state,
        city: {
          text: e.target.value,
          value: null,
          suggestions: suggestions,
          loading: false,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event) => {
    setState({
      ...state,
      errorFields: [],
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(state.reg_email!=state.reg_email2){
      setState({...state,error : (<>Gli indirizzi email inseriti non coincidono.</>), errorFields : ["reg_email", "reg_email2"]});
      return;
    }
    if(state.city.value==null){
      setState({...state,error : (<>Selezionare una città.</>), errorFields : ["reg_city"]});
    }
    try {
      let formData = new FormData();
      formData.append("name", state.reg_name);
      formData.append("surname", state.reg_surname);
      formData.append("email", state.reg_email);
      formData.append("password", state.reg_password);
      formData.append("profession", state.reg_qualifica);
      formData.append("city", state.city.value);
      if(params && params.registrationToken){
        formData.append("token", params.registrationToken);
      }
      let res = await fetch(Config.BASE_URL + "user/register/", {
        method: "POST",
        body: formData,
      });
      let resJson = await res.json();
      if (res.status === 200) {
        if(resJson.success){
          setState({...state,success : true});
        }else{
          var newState = {...state, error : "Impossibile procedere"}
          if(resJson.error){
            switch(resJson.error){
              case "EXISTING_EMAIL":
                newState = {error : (<>Utente già registrato, prova a recuperare la password</>)}
                break;
            }
          }
          setState(newState);
        }
      } else {
        
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (state.success ? <Navigate to="/registered" replace /> : (
    state.error && params && params.registrationToken && state.token_data==null ? <Navigate to="/tokenError" replace /> : 
      <> 
        <header class="bg-dark border-bottom border-8 border-primary">
          <div class="container d-flex flex-wrap justify-content-center py-5">
            <Link to="/"><span class="fs-2 text-white">TiTurno</span></Link>
          </div>
        </header>
        <div class="container pt-5">
          <div class="row justify-content-around">
            <div class="col-md-8 col-lg-6">
              { state.token_data==null &&
                <>
                  <h6 class="text-center mb-5">Iscriviti a TiTurno</h6>
                  <ul class="nav nav-pills row mb-5" id="myTab" role="tablist">
                    <li class="nav-item col" role="presentation">
                      <button class="nav-link active w-100" id="professionista-tab" data-bs-toggle="tab" data-bs-target="#professionistaTabContent" type="button" role="tab" aria-controls="home" aria-selected="true">Sono un professionista</button>
                    </li>
                    <li class="nav-item col" role="presentation">
                      <button class="nav-link w-100" id="struttura-tab" data-bs-toggle="tab" data-bs-target="#strutturaTabContent" type="button" role="tab" aria-controls="profile" aria-selected="false">Rappresento una struttura</button>
                    </li>
                  </ul>
                </>
              }
              { state.token_data!=null &&
                <>
                  <h6 class="text-center mb-4">Sei stato invitato ad iscriverti a TiTurno</h6>
                  <div class="alert alert-primary border mx-2 mb-4" role="alert">
                  {state.token_data.structure} - {state.token_data.structureGroup}
                  </div>
                </>
              }
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="professionistaTabContent" role="tabpanel" aria-labelledby="professionista-tab">
                  <form onSubmit={handleSubmit} class="border-bottom pb-5" id="registration" autocomplete="off">
                  <input autocomplete="false" name="hidden" type="text" style={{display:"none"}} />
                  {[
                    {
                      field : "reg_email",
                      type : "email",
                      label : "Qual'è il tuo indirizzo Email",
                      disabled : state.token_data!=null
                    },
                    {
                      field : "reg_email2",
                      type : "email",
                      label : "Ripeti il tuo indirizzo Email",
                      hidden : state.token_data!=null
                    },
                    {
                      field : "reg_password",
                      type : "password",
                      label : "Crea una Password"
                    },
                    {
                      field : "reg_name",
                      type : "text",
                      label : "Qual'è il tuo Nome"
                    },
                    {
                      field : "reg_surname",
                      type : "text",
                      label : "Qual'è il tuo Cognome"
                    },
                    ].map((fieldValue) => {
                      return (
                        !fieldValue.hidden && <>
                          <div class="form-floating">
                            <input type={fieldValue.type} class={"form-control " + (state.errorFields.includes(fieldValue.field) ? "border-danger" : (state[fieldValue.field].length>0 ? "border-primary" : ""))} name={fieldValue.field} id={fieldValue.field} value={state[fieldValue.field]} onChange={handleChange} required disabled={fieldValue.disabled} autocomplete={fieldValue.type == "password" ? "new-password" : "off"}  />
                            <label for={fieldValue.field}>{fieldValue.label}</label>
                          </div>
                          <br/>
                        </>
                      )
                  })}
                  <div class="form-floating">
                    <select class={"form-control " + (state.reg_qualifica!=-1 ? "border-primary" : "")} name="reg_qualifica" id="reg_qualifica" value={state.reg_qualifica} onChange={handleChange} required disabled={state.token_data!=null}>
                      {state.token_data!=null && state.token_data.spec==-1 ? (<><option value="999" selected>{getRuoloName(state.token_data.role)}</option></>): (<><option value="-1" disabled selected>Selezionare</option><SpecsOptions></SpecsOptions></>)}
                    </select>
                    <label for="reg_qualifica">Qual'è la tua qualifica</label>
                  </div>
                  <br/>
                  <div class="form-floating">
                    <input type="text" class={"form-control " + (state.city.value!=null ? "border-primary" : "")}
                      list={state.city.suggestions!=null && state.city.suggestions.length>0 ? "citiesList" : ""}  name="reg_city" id="reg_city" value={state.city.text} onChange={handleCityChange} onBlur={handleCityBlur} required />
                    {
                      state.city.loading ? (
                        <ul class="list-group">
                          <li class="list-group-item d-none">FakeItem</li>
                          <li class="list-group-item text-center"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></li>
                        </ul>) :
                      (state.city.suggestions!=null ? (
                        <ul class="list-group">
                          <li class="list-group-item d-none">FakeItem</li>
                          {
                            state.city.suggestions.map((fieldValue) => {
                              return ( <li class="list-group-item autocomplete-item" onMouseDown={()=>{
                                setState({
                                  ...state,
                                  city : {
                                    text : fieldValue.comune,
                                    suggestions : null,
                                    loading : false,
                                    value : fieldValue.id,
                                  }
                                }
                                )
                              }}>{fieldValue.comune}</li> );
                            })
                          }
                        </ul>
                      ) : (<></>))
                    }
                    <label for="reg_city">Qual'è la tua città di residenza</label>
                  </div>
                  <br/>
                  { state.error ? 
                    (<><h6 class="text-danger text-center">{state.error}</h6><br /></>):(<></>)}
                  <div class="container-fluid mt-3">
                    <div class="row">
                      <div class="col">
                      </div>
                      <button type="submit" class="btn btn-primary col">Registrati</button>
                    </div>
                  </div>
                </form>
                </div>
                <div class="tab-pane fade border-bottom pb-5 text-center fs-5" id="strutturaTabContent" role="tabpanel" aria-labelledby="struttura-tab">
                  Contatta i nostri referenti commerciali al:
                  <br />
                  <br />
                  <a href='tel:3274748914'>3274748914</a>
                </div>
              </div>
              <div class="text-center mt-5 mb-1">
                Sei già registrato?
              </div>
              <div class="row justify-content-around">
                <Link to="/login" className='btn btn-outline-dark col-6 mt-2'>Accedi</Link>
              </div>
              <br/>
              <div class="text-center mb-5">
                oppure
                <br/>
                <br/>
                <Link to="/forgotPassword">Hai dimenticato la password?</Link>
              </div>
              
              <br/>
            </div>
          </div>
        </div>
      </>
    ));
}

export default RegisterPage;