
import React from 'react';
import {
  Link
} from "react-router-dom";
import * as Config from './../../Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUserGroup, faSearch } from '@fortawesome/free-solid-svg-icons';
import Calendar from '../calendar';
import ItalyMap from '../ItalyMap';
import Spec_DashboardPage from './specialist/dashboard';
import DateInfinity from '../calendar_vertical/DateInfinity';

export default class DashBoardPage extends React.Component {

  constructor(props) {
    super(props);
    this.state =  {
      city : {
        text : '',
        suggestions : null,
        loading : false,
        value : null,
        lat : null,
        lon : null,
      },
      prevCity : null,
      range : 25,
      searchResults : null,
    };
    if(this.props.user.city){
      this.state.city.text = this.props.user.city.comune
      this.state.city.lat = this.props.user.city.lat
      this.state.city.lon = this.props.user.city.lon
      this.state.prevCity = this.state.city
    }
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.handleCityBlur = this.handleCityBlur.bind(this);
    this.onSearchClicked = this.onSearchClicked.bind(this);
  }


  render() {
    return (
      <>
        <div class="container-fluid">
          <div class="row">
            {!this.props.user.hasRole("professional") ? (
              <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
              <div class="position-sticky pt-3">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    {  this.props.user.hasCapability("edit_structure") && 
                      <Link to="/crud/structures" className='nav-link'>
                        <FontAwesomeIcon icon={faHome}/>&nbsp;&nbsp;Gestione Strutture
                      </Link>
                    }
                    {  this.props.user.hasCapability("edit_structure_groups") && 
                      <Link to="/crud/structure_groups" className='nav-link'>
                        <FontAwesomeIcon icon={faUserGroup}/>&nbsp;&nbsp;Gestione Gruppi
                      </Link>
                    }
                    {  this.props.user.hasCapability("create_shifts") && 
                      <Link to="/crud/structures" className='nav-link'>
                        Gestione Strutture
                      </Link>
                    }
                  </li>
                </ul>
              </div>
            </nav>
            ) : (<></>)}
            {this.props.user.hasRole("professional") ? (
              <></>
            ) : (this.props.user.hasRole("admin") ? (
                <></>
              ) : (
                <></>
              )
            )}
          </div>
        </div>
      </>
    );
  }
}