
import DashBoardPage from './Dashboard';
import React from 'react';
import AssignManager from './structures/AssignManager'
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Route,
  useParams,
} from "react-router-dom";
import CreaReparto from './director/creaReparto';
import CrudManagerBuilder from '../CrudManagerBuilder';
import Spec_DashBoardPage from './specialist/dashboard';
import Admin_DashBoardPage from './admin/dashboard';
import Dir_DashBoardPage from './director/dashboard';
import Group_DashBoardPage from './group_manager/dashboard';
import InvitaPersonale from './director/InvitaPersonale';

export default class AuthContext extends React.Component {

  constructor(props) {
    super(props);
    this.doLogout = this.doLogout.bind(this);
    this.withParams = function (Component) {
      return props => <Component {...props} params={useParams()} />;
    }
  }

  async doLogout(e) {
    e.preventDefault();
    // TODO request di logout, al momento elimina solo il token!
    this.props.user.doLogout();
  }

  

  render() {
    return (
      <>
        <div>
          <header class="bg-dark border-bottom border-8 border-primary">
            <div class="container d-flex flex-wrap justify-content-center py-3">
              <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                <svg class="bi me-2" width="40" height="32"></svg>
                <div class="fs-4 text-white pe-5 border-end border-1">TiTurno</div>
                <div class="fs-4 text-white ps-5">Benvenuto {this.props.user.name}</div>
              </a>
              <ul class="nav">
                <li class="nav-item"><a class="btn btn-danger px-5" onClick={this.doLogout}>Esci</a></li>      
              </ul>
            </div>
          </header>
            <div class="container content">
              <React.StrictMode>
                <RouterProvider router={createBrowserRouter([
                  {
                    path: "*",
                    element: <Navigate to="/dashboard" replace />,
                  },
                  {
                    path: "/dashboard",
                    element: (
                      this.props.user.hasRole("admin") ? 
                        <Admin_DashBoardPage user={this.props.user}/>
                      : ( this.props.user.hasRole("director") ? 
                        <Dir_DashBoardPage user={this.props.user}/> : (
                          (this.props.user.hasRole("group_manager") ? 
                            <Group_DashBoardPage user={this.props.user}/> :
                            <Spec_DashBoardPage user={this.props.user}/>
                          )
                        )
                      )
                    )
                  },
                  {
                    path: "/crud/structures",
                    element: <CrudManagerBuilder user={this.props.user} entity="structure"/>,
                  },
                  {
                    path: "/creaReparto/:idStructure",
                    element: <CreaReparto user={this.props.user}/>,
                  },
                  {
                    path: "/modificaReparto/:idStructure/:idReparto",
                    element: <CreaReparto user={this.props.user}/>,
                  },
                  {
                    path: "/invitaCaporeparto/:idReparto",
                    element: <InvitaPersonale user={this.props.user} userLevel="20"/>,
                  },
                  {
                    path: "/invitaPersonale/:idReparto",
                    element: <InvitaPersonale user={this.props.user} userLevel="1"/>,
                  },
                  {
                    path: "/crud/structure_groups/:id",
                    element: <CrudManagerBuilder user={this.props.user} entity="structure_group"/>,
                  },
                  {
                    path: "/structures/assignManager/:id",
                    element: <AssignManager />,
                  },
                  
                ])} />
              </React.StrictMode>
          </div>
        </div>
      </>
    );
  }
}