

import React, { useState, useEffect }  from 'react';
import {
    Link, Navigate, useParams
  } from "react-router-dom";
import * as Config from './../../../Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import Calendar from '../../calendar';

function CreaReparto(props){
    const [formData, setFormData] = useState({
        success : false,
        idReparto : null,
        nomeReparto : "",
        error : null,
    });
    const params = useParams();
    useEffect(()=>{
        if(params.idReparto && formData.idReparto==null){
            let postData = new FormData();
            postData.append("id", params.idReparto);
            fetch(Config.BASE_URL + "structure_group/get/", {
                method: "POST",
                headers: new Headers({
                    'Authorization': 'Bearer ' + props.user.getUserToken, 
                }),
                body: postData,
            }).then(res => res.json())
            .then((res) => {
                if(res.success){
                    setFormData({...formData, idReparto : res.res.id , nomeReparto : res.res.name });
                    return
                }
            });
        }
    })
    const handleChange = (event) => {
        setFormData({...formData, 
            nomeReparto : event.target.value,
            error : null,
        });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(formData.nomeReparto == null || formData.nomeReparto.length==0){
          this.setFormData({...formData, error : (<>Definire un nome del reparto</>)});
          return;
        }
        try {
            let postData = new FormData();
            postData.append("name", formData.nomeReparto);
            postData.append("id_structure", params.idStructure);
            let res = await fetch(Config.BASE_URL + "structure_group/create/", {
                method: "POST",
                headers: new Headers({
                    'Authorization': 'Bearer ' + props.user.getUserToken, 
                }),
                body: postData,
            });
            let resJson = await res.json();
            if (res.status === 201) {
                if(resJson.success){
                    setFormData({...formData, success : true});
                    return
                }
            }
            this.setFormData({...formData, error : "Impossibile procedere, riprovare più tardi"});
        } catch (err) {
          console.log(err);
        }
    }
    if(formData.success){
        return (
          <Navigate to="/repartoCreato" replace />
        );
    }
    return (
        <> 
            <div class="container pt-5">
                <div class="row justify-content-around">
                    <div class="col-md-8 col-lg-6">
                        <h6 class="text-center mb-5">{formData.idReparto == null ? (<>Crea un nuovo reparto</>) : (<>Modifica reparto</>)}</h6>
                        <form onSubmit={handleSubmit} class="pb-5" id="formReparto" autocomplete="off">
                            <input autocomplete="false" name="hidden" type="text" style={{display:"none"}} />
                            <div class="form-floating">
                                <input type="text" class={"form-control " + (formData.error!=null ? "border-danger" : (formData.nomeReparto.length>0 ? "border-primary" : ""))} name="nomeReparto" id="nomeReparto" value={formData.nomeReparto} onChange={handleChange} required autocomplete="off"  />
                                <label for="nomeReparto">Nome del reparto</label>
                            </div>
                            <br/>
                            { formData.error ? 
                                (<><h6 class="text-danger text-center">{formData.error}</h6><br /></>):(<></>)}
                            <div class="container-fluid mt-3">
                                <div class="row">
                                    <div class="col">
                                    </div>
                                    {formData.idReparto != null && <button class="btn btn-danger col me-5">Elimina</button>}
                                    <button type="submit" class="btn btn-primary col">{formData.idReparto == null ? (<>Crea</>) : (<>Modifica</>)}</button>
                                </div>
                            </div>
                        </form>
                    </div>     
                </div>
            </div>
        </>
    );
}
export default CreaReparto;