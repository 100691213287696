

import React from 'react';
import BootstrapModal from 'react-bootstrap/Modal';

export default class Modal extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BootstrapModal show={this.props.isOpen} ref={this.modalRef} id={this.props.id} onHide={this.props.onHide}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{this.props.title}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>{this.props.children}</BootstrapModal.Body>
      </BootstrapModal>
    );
  }
}


