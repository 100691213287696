
import React, { useEffect, useState } from 'react';
import * as Config from './../Config'
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

export default class CrudManager extends React.Component {

  // TODO update

    constructor(props) {
      super(props);
      this.state = {fields : {}}
      this.props.config.fields.map((k)=>{
        this.state.fields[k] = k
      })
    }

    refresh(){
      fetch(Config.BASE_URL + this.props.config.entity + "/list",{
        headers: new Headers({
            'Authorization': 'Bearer ' + this.props.user.getUserToken, 
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.success && result.res!=null){
            this.setState({pageData : result.res});
              return;
          }
        }
      )
    }

    componentDidMount() {
      this.refresh()
    }

    handleChange(event) {
      var newState = {fields : this.state.fields}
      newState.fields[event.target.name] = event.target.value
      this.setState(newState);
    }

    handleSubmit(e) {
      e.preventDefault();
      var tthis = this;
      try {
        fetch(Config.BASE_URL + this.props.config.entity + "/create", {
          method: "POST",
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.user.getUserToken
          }),
          body: JSON.stringify(this.state.fields)
        }).then(res => res.json())
        .then(
          (result) => {
            if(result.success && result.res!=null){
              tthis.refresh();
              this.crudModal.toggle()
            }
          }
        )
      } catch (err) {
        console.log(err);
      }
    }

    buildTableBody(){
      if(!this.state.pageData || !this.state.pageData.data || !this.state.pageData.data.length>0){
        return (<tr><td colspan={this.props.config.columns.length}>Nessun elemento disponibile</td></tr>);
      }
      var builtHtml = []
      this.state.pageData.data.forEach(element => {
        builtHtml.push(
          <tr>{this.buildTableBodyRow(element)}</tr>
        )
      });
      return builtHtml
    }

    buildTableBodyRow(rowData){
      var builtHtml = []
      var tthis = this;
      {this.props.config.columns.map(function(colDefinition){
        if(colDefinition.isDelete){
          builtHtml.push(<td onClick={()=>{
            if(window.confirm("Eliminare l'elemento?")){
              console.log(rowData)
              fetch(Config.BASE_URL + tthis.props.config.entity + "/delete/" + rowData["id"], {
                method: "DELETE",
                headers: new Headers({
                  'Authorization': 'Bearer ' + tthis.props.user.getUserToken
                })
              }).then(res => res.json())
              .then(
                (result) => {
                  if(result.success){
                    tthis.refresh();
                  }
                }
              )
            }
          }}><FontAwesomeIcon icon={faTrash}/></td>)
        }else if(colDefinition.isEdit){
          builtHtml.push(<td onClick={()=>{
          }}><FontAwesomeIcon icon={faEdit}/></td>)
        }else if(colDefinition.custom){
          builtHtml.push(colDefinition.custom(rowData))
        }else{
          var aCellClass = ""
          aCellClass += colDefinition.hidden ? "hidden " : ""
          aCellClass += colDefinition.isId ? "isId " : ""
          builtHtml.push(<td class={aCellClass.trim()}>{rowData[colDefinition.field]}</td>)
        }
      })}
      return builtHtml
    }

    buildForm(){
      var currState = this.state;
      const handleChange = this.handleChange
      var builtHtml = []
      this.props.config.fields.forEach(element => {
        builtHtml.push(
          <label>
            {element}
            <input type="text" name={element} value={currState.fields[element]} onChange={this.handleChange.bind(this)} />
          </label>
        )
      });
      builtHtml.push(
        <input type="submit" value="Aggiungi" class="pure-button pure-button-primary"/>
      )
      return builtHtml;
    }
  
    render() {
        return (
          <>
            <table class="pure-table">
              <thead>
            {this.props.config.columns.map(function(aColumn){
                return !aColumn.hidden ? (
                  <th>{aColumn.label}</th>
                ) : (<th class="hidden"></th>)
            })}
            </thead>
            <tbody>
            {this.buildTableBody()}
            </tbody>
            </table>
            <br/>
            { this.props.config.canCreate ? 
            (<label class="pure-button pure-button-primary" data-bs-toggle="modal" data-bs-target={"#modal-crud-" + this.props.config.entity}>Aggiungi {this.props.config.entityName}</label>):(<></>)}
            <Modal ref={crudModal => this.crudModal = crudModal} id={"modal-crud-" + this.props.config.entity} title={"Creazione " + this.props.config.entityName}>
              <form class="pure-form pure-form-stacked" onSubmit={this.handleSubmit.bind(this)}>
                <fieldset>
                  {this.buildForm()}
              </fieldset>
              </form>
            </Modal>
          </>
        )
    }
  }
  