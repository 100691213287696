
import React from 'react';
import * as Config from '../../Config'
import {
  Link
} from "react-router-dom";

export default class RegisteredPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {email: '', password : ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    var newState = {}
    newState[event.target.name] = event.target.value
    this.setState(newState);
  }

  async handleSubmit(e) {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("email", this.state.email);
      formData.append("password", this.state.password);
      let res = await fetch(Config.BASE_URL + "user/login/", {
        method: "POST",
        body: formData,
      });
      let resJson = await res.json();
      if (res.status === 200 && resJson.success) {
        this.props.onLoginSuccess(resJson.app_token)
        //
      } else {
        
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  render() {
    return (
      <> 
        <header class="bg-dark border-bottom border-8 border-primary">
          <div class="container d-flex flex-wrap justify-content-center py-5">
            <Link to="/"><span class="fs-2 text-white">TiTurno</span></Link>
          </div>
        </header>
        <div class="container pt-5">
          <div class="row justify-content-around">
            <div class="col-md-8 col-lg-6">
              {this.props.tokenError ? (
                <>
                  <h6 class="text-center mb-5">Impossibile registrarsi dell'invito</h6>
                  <p class="fs-5 text-center">
                    Contattare la proprio persona di riferimento per richiedere un nuovo invito.
                  </p>
                  <div class="container-fluid text-center">
                    <Link to="/" className='btn btn-primary col-6 mt-2'>Chiaro</Link>
                  </div>
                </>) : (
                <>
                  <h6 class="text-center mb-5">Prima di accedere a TiTurno</h6>
                  <p class="fs-5 text-center">
                    Verifica la casella email indicata in fase di registrazione e apri il link di convalida dell'indirizzo.
                  </p>
                  <p class="fs-6 text-center mb-5">
                    Se non trovi l'email verifica anche negli indesiderati o spam.
                  </p>
                  <div class="container-fluid text-center">
                    <Link to="/login" className='btn btn-primary col-6 mt-2'>Fatto</Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}