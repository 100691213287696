

import React, { useState, useEffect }  from 'react';
import {SpecsOptions, getRuoloName} from '../../form/specsOptions';
import {
    Link, Navigate, useParams
  } from "react-router-dom";
import * as Config from '../../../Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import Calendar from '../../calendar';

function InvitaPersonale(props){
    const pageConfig = {
        "20" : {
            targetUrl : "direttore/inviteCaporeparto/",
            hasSpecSelect : false,
        },
        "1" : {
            targetUrl : "caporeparto/invitePersonale/",
            hasSpecSelect : true,
        }
    }
    const [formData, setFormData] = useState({
        success : false,
        successDismissed : false,
        idReparto : null,
        qualifica : -1,
        nomeReparto : null,
        emailInvitato : "",
        error : null,
        isLoading : false
    });
    const params = useParams();
    useEffect(()=>{
        if(params.idReparto && formData.idReparto==null && !formData.isLoading){
            setFormData({...formData, isLoading : true});
            let postData = new FormData();
            postData.append("id", params.idReparto);
            fetch(Config.BASE_URL + "structure_group/get/", {
                method: "POST",
                headers: new Headers({
                    'Authorization': 'Bearer ' + props.user.getUserToken, 
                }),
                body: postData,
            }).then(res => res.json())
            .then((res) => {
                if(res.success){
                    setFormData({...formData, idReparto : res.res.id , nomeReparto : res.res.name });
                    return
                }
            });
        }
    }, [formData])
    const handleChange = (event) => {
        setFormData({...formData, 
            emailInvitato : event.target.value,
            error : null,
        })
    }
    const handleSelectChange = (event) => {
        setFormData({...formData, 
            qualifica : event.target.value
        })
      }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(formData.emailInvitato == null || formData.emailInvitato.length==0){
          setFormData({...formData, error : ("Inserire l'indirizzo email del " + getRuoloName(props.userLevel))});
          return;
        }
        try {
            let postData = new FormData();
            postData.append("email", formData.emailInvitato);
            postData.append("id_reparto", formData.idReparto);
            if(pageConfig[props.userLevel].hasSpecSelect){
                postData.append("spec", formData.qualifica);
            }
            let res = await fetch(Config.BASE_URL + pageConfig[props.userLevel].targetUrl, {
                method: "POST",
                headers: new Headers({
                    'Authorization': 'Bearer ' + props.user.getUserToken, 
                }),
                body: postData,
            });
            let resJson = await res.json();
            if (res.status === 200) {
                if(resJson.success){
                    setFormData({...formData, success : true});
                    return
                }
            }
            setFormData({...formData, error : "Impossibile procedere, riprovare più tardi"});
        } catch (err) {
          console.log(err);
        }
    }
    if(formData.success){
        return (
            <div class="container pt-5">
                <div class="row justify-content-around">
                    <div class="col-md-8 col-lg-6 text-center">
                        <h6 class="text-center mb-5">Email inviata correttamente, ora sarà la persona indicata a dover terminare il processo di registrazione</h6>
                        <Link className="btn btn-primary" to={"/"}>Torna alla dashboard</Link>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <> 
            <div class="container pt-5">
                <div class="row justify-content-around">
                    <div class="col-md-8 col-lg-6">
                        <h6 class="text-center mb-5">Invita {getRuoloName(props.userLevel)} per il reparto "{formData.nomeReparto}"</h6>
                        <form onSubmit={handleSubmit} class="pb-5" id="formReparto" autocomplete="off">
                            <input autocomplete="false" name="hidden" type="text" style={{display:"none"}} />
                            <div class="form-floating">
                                <input type="text" class={"form-control " + (formData.error!=null ? "border-danger" : (formData.emailInvitato.length>0 ? "border-primary" : ""))} name="emailInvitato" id="emailInvitato" value={formData.emailInvitato} onChange={handleChange} autocomplete="off"  />
                                <label for="emailInvitato">Indirizzo email del {getRuoloName(props.userLevel)}</label>
                            </div>
                            <br/>
                            {pageConfig[props.userLevel].hasSpecSelect && <><div class="form-floating">
                                <select class={"form-control " + (formData.qualifica!=-1 ? "border-primary" : "")} name="qualifica" id="qualifica" value={formData.qualifica} onChange={handleSelectChange} required>
                                <option value="-1" disabled selected>Selezionare</option>
                                <SpecsOptions></SpecsOptions>
                                </select>
                                <label for="qualifica">Qual'è la qualifica</label>
                            </div>
                            <br/></>}
                            { formData.error ? 
                                (<><h6 class="text-danger text-center">{formData.error}</h6><br /></>):(<></>)}
                            <div class="container-fluid mt-3">
                                <div class="row">
                                    <div class="col">
                                    </div>
                                    <button type="submit" class="btn btn-primary col">Invia invito</button>
                                </div>
                            </div>
                        </form>
                    </div>     
                </div>
            </div>
        </>
    );
}
export default InvitaPersonale;