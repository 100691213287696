import React, { Component } from 'react';
import * as Config from '../../Config'
import Moment from 'react-moment';
import moment from 'moment';
import './DateInfinity.css';


export default class DateInfinity extends Component {

  constructor(props) {
    super(props)

    this.myComp = null;
    this.setMyCompRef = element => {      
      this.myComp = element;    
    };

    this.scrollTimeout = null;

    let options = [];
    let today = moment().format("YYYY-MM-DD");
    let startDate = moment().subtract(51, 'days').startOf('day');
    for (let index = 0; index < 100; index++) {
      let newId = startDate.add(1, "days").format("YYYY-MM-DD")
      options.push(
        {
          id: newId,
          data: null,
          isToday : newId == today,
        }
      );
    }

    this.prefs = {
      elementHeight: 80,
    }


    this.state = {
      selectedDay: 10, 
      items: options,
      events: props.events,
    };
  }


  loadLessDates(element) {

    let options = [];
    options = this.state.items;

    let fromDate = moment(options[0].id);
    let firstDate = moment(options[0].id);
    // console.log(startDate.toLocaleString('it-IT'));
    for (let index = 0; index < 100; index++) {
      options.unshift(
        {
          id: firstDate.subtract(1, "days").format("YYYY-MM-DD"),
          data: null,
        }
      );
      // options.pop();  // remove last after insert first
    }

    this.setState({
      items: options  
    });
    element.scrollBy(0, this.prefs.elementHeight);
    let dateTo = fromDate.format("YYYY-MM-DD");
    this.getEvents(fromDate.subtract(50, 'days').format("YYYY-MM-DD"), dateTo);
  };


  loadMoreDates(element) {

    let options = [];
    options = this.state.items;
    let firstDate =moment(options.at(-1).id)
    for (let index = 0; index < 100; index++) {
      options.push({
        id: firstDate.add(1, "days").format("YYYY-MM-DD"),
        data: null,
      });
      // options.shift();  // remove first after insert last
    }

    this.setState({
      items: options   
    });
    this.getEvents(firstDate.format("YYYY-MM-DD"), firstDate.add(50, 'days').format("YYYY-MM-DD"));
  };


  getEvents(dateFrom, dateTo) {
    console.log(dateFrom)
    console.log(dateTo)
    let formData = new FormData();
      formData.append("dateFrom", dateFrom);
      formData.append("dateTo", dateTo);
    fetch(Config.BASE_URL + "user/shifts/", {
      method: "POST",
      body: formData,
    })
    .then(res => res.json())
    .then(
      (result) => {
        let mergedItems = {...this.state}
        mergedItems.items.forEach((e)=>{
          if(result.res[e.id]){
            e.data = result.res[e.id];
          }
          return e;
        })
        this.setState({ items: mergedItems.items });        
      },
      (error) => {
        // this.setState({
        //   isLoaded: true,
        //   error
        // });
      }
    )
  }


  /**
   * check if element is in the middle and select it
   * @param {*} element 
   */
  checkCenterPosition(element) {
    let offset = this.state.elementHeigth/2;
    let parentDivCenter = element.parentNode.getBoundingClientRect().height/2;
    // posizione centrale verticale
    let centerTop = element.getBoundingClientRect().top - element.parentNode.getBoundingClientRect().top + element.getBoundingClientRect().height/2;
    if (centerTop >= parentDivCenter-offset && centerTop <= parentDivCenter+offset) {
      element.classList.add("selected");
      console.log("checkCenterPosition")
      this.getEvents(element);
    } else
      element.classList.remove('selected');
  }

  scrollEndEvent() {
      // let myItems = this.myComp.getElementsByClassName('item');
      // for (let item of myItems) {
      //   this.checkCenterPosition(item);
      // }
  }

  handleClick = (eventJson) => {
    this.props.parentCallback(eventJson);
  };

  handleScroll = e => {
    let element = e.target;

    if (element.scrollTop === 0) {
      this.loadLessDates(element);
    }

    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      this.loadMoreDates(element); 
    }
  }
  
  componentDidMount() {
    // if (this.state.firstScroll === false) return;

    // this.myComp.scrollTo(0, this.myComp.scrollHeight / 2);
    // this.setState({
    //   firstScroll: false   
    // });
    this.myComp.scrollTo(0, (this.myComp.scrollHeight / 2) - (this.prefs.elementHeight * 2));
    
    this.getEvents(this.state.items[0].id, this.state.items[this.state.items.length-1].id);
  }


  render() {
    return(
      <div className='DateInfinity'
          ref={this.setMyCompRef}
          onScroll={this.handleScroll}
        >
          {
            this.state.items.map(item => (
              <div className={"row item " + (item.isToday ? "today" : "")} key={item.label} id={item.id} style={{minHeight: this.prefs.elementHeight + 'px'}} >
                <div class="col-md-auto item-day"><Moment format="DD" className='fs-1'>{item.id}</Moment><br/></div>
                <div class="col-md-auto item-monthYear">
                  <b><Moment format="MMMM" locale="it" className='text-uppercase'>{item.id}</Moment></b><br/>
                  <Moment format="YYYY">{item.id}</Moment>
                </div>
                <div className='item-date col pt-3'>
                  {item.data !=null &&    
                    <><button className='item-event' onClick={() => this.handleClick(item.eventJson)}>{item.data.l}</button><br /><small>{item.data.sl}</small></>
                  }
                </div>
              </div>
            ))
          }
      </div>
    )
  }

}
