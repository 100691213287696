
import React from 'react';
import {
  Link
} from "react-router-dom";
import * as Config from '../../../Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUserGroup, faSearch, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Calendar from '../../calendar';
import ItalyMap from '../../ItalyMap';
import DateInfinity from '../../calendar_vertical/DateInfinity';

export default class Spec_DashBoardPage extends React.Component {

  constructor(props) {
    super(props);
    this.state =  {
      city : {
        text : '',
        suggestions : null,
        loading : false,
        value : null,
        lat : null,
        lon : null,
      },
      prevCity : null,
      range : 25,
      searchResults : null,
    };
    if(this.props.user.city){
      this.state.city.text = this.props.user.city.comune
      this.state.city.lat = this.props.user.city.lat
      this.state.city.lon = this.props.user.city.lon
      this.state.prevCity = this.state.city
    }
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.handleCityBlur = this.handleCityBlur.bind(this);
    this.onSearchClicked = this.onSearchClicked.bind(this);
  }

  async onSearchClicked(e){
    try {
      let formData = new FormData();
      // formData.append("q", e.target.value); >>> TODO
      let res = await fetch(Config.BASE_URL + "user/shifts/find", {
        method: "POST",
        body: formData,
      });
      let resJson = await res.json();
      if (res.status === 200 && resJson.success) {
        this.setState({searchResults : resJson.res}) 
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleCityBlur(e){
    if(this.state.city.value==null){
      this.setState({city : this.state.prevCity!=null ? this.state.prevCity : {
        text : "",
        suggestions : null,
        loading : false,
        value : null,
        lat : null,
        lon : null,
      }});
    }   
  }

  handleRangeChange(e){
    this.setState({
      range : e.target.value,
    });
  }

  async handleCityChange(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({city : {
      text : e.target.value,
      suggestions : null,
      loading : true,
      value : null,
    }});
    try {
      let formData = new FormData();
      formData.append("q", e.target.value);
      let res = await fetch(Config.BASE_URL + "cities/search/", {
        method: "POST",
        body: formData,
      });
      let resJson = await res.json();
      var suggestions = null;
      if (res.status === 200 && resJson.success) {
        suggestions = resJson.res
      }
      this.setState({city : {
        text : e.target.value,
        value : null,
        suggestions : suggestions,
        loading : false,
      }})
    } catch (err) {
      console.log(err);
    }
  }


  render() {
    return (
      <>
        <div class="container-fluid">
          <div class="row">
            <div style={{position : 'absolute', top : '78px', left: '0', right : '0', bottom : '0', overflowX : "hidden"}}>
              <div class="container">
                <div class="row pt-4 mb-5" style={{minHeight : "800px" }}>
                  <div class="col-md-4 dashboardCard bg-white d-flex flex-column clearfix p-0" style={{position: "relative"}}>
                      <h5 class="text-center bg-dark text-white p-3" style={{borderTopLeftRadius : "8px", borderTopRightRadius : "8px"}}>I tuoi impegni</h5>
                      <DateInfinity style={{marginTop : "70px"}}></DateInfinity>
                  </div>
                  <div class="col-md-8" style={{position : 'relative'}}>
                    <div class="dashboardCard bg-white">
                      <h5 class="text-center bg-dark text-white p-3" style={{borderTopLeftRadius : "8px", borderTopRightRadius : "8px"}}>Trova una nuova opportunità di lavoro</h5>
                      <div class = "row ps-2 pe-2 pb-2">
                        <div class="col form-floating">
                          <input type="text" class={"form-control " + (this.state.city.value!=null ? "border-primary" : "")}
                            list={this.state.city.suggestions!=null && this.state.city.suggestions.length>0 ? "citiesList" : ""}  name="reg_city" id="reg_city" value={this.state.city.text} onChange={this.handleCityChange} onBlur={this.handleCityBlur} required />
                          {
                            this.state.city.loading ? (
                              <ul class="list-group">
                                <li class="list-group-item d-none">FakeItem</li>
                                <li class="list-group-item text-center"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></li>
                              </ul>) :
                            (this.state.city.suggestions!=null ? (
                              <ul class="list-group">
                                <li class="list-group-item d-none">FakeItem</li>
                                {
                                  this.state.city.suggestions.map((fieldValue) => {
                                    return ( <li class="list-group-item autocomplete-item" onMouseDown={()=>{
                                      let newCity = {
                                        text : fieldValue.comune,
                                        suggestions : null,
                                        loading : false,
                                        value : fieldValue.id,
                                        lat : fieldValue.lat,
                                        lon : fieldValue.lon
                                      }
                                      this.setState(
                                        {
                                          city : newCity,
                                          prevCity : newCity
                                        }
                                      )
                                    }}>{fieldValue.comune}</li> );
                                  })
                                }
                              </ul>
                            ) : (<></>))
                          }
                          <label class="ps-4" for="reg_city"><b>Città</b></label>
                        </div>
                        <div className='form-floating' style={{width: "140px", maxWidth: "140px", minWidth : "140px"}}>
                            <select class="form-select" value={this.state.range} onChange={this.handleRangeChange}>
                                <option value="10">10 Km</option>
                                <option value="25">25 Km</option>
                                <option value="50">50 Km</option>
                                <option value="100">100 Km</option>
                            </select>
                            <label class="ps-4" for="reg_city"><b>Raggio</b></label>
                          </div>
                          <div class="col-md-auto text-center">
                            <div className='btn btn-primary' style={{minWidth : "140px"}} onClick={this.onSearchClicked}>
                              Mostra&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faSearch}/>
                            </div><br/>
                            <span style={{fontSize : "12px", fontWeight : "bold", color : "#212529", opacity : ".65"}}>10+ risultati</span>
                          </div>
                      </div>
                      <div class="container">
                      { this.state.searchResults != null && 
                        this.state.searchResults.map((aSearchResult) => {
                          return ( <div class="row border-top border-1">
                            <div class="col p-5">Struttura #{aSearchResult.s}</div>
                            <div class="col p-5">{aSearchResult.d}<br /><span class="fs-5">{aSearchResult.v}</span></div>
                            <div class="col p-5"><span class="badge bg-success text-white fs-4">{aSearchResult.r} €</span></div>
                            <div class="col py-5" style={{maxWidth:"50px"}}><FontAwesomeIcon icon={faChevronRight}/></div>
                          </div>);
                        })
                      }
                      </div>
                    </div>
                    <div>
                      { this.state.searchResults == null && 
                        <>
                          <br />
                          <ItalyMap lat = {this.state.city.lat} lon = {this.state.city.lon} radius = {this.state.range} dotColor="#00bcd4" ></ItalyMap>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}