
import HomePage from './Home';

import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import RegisteredPage from './RegisteredPage';

import React from 'react';
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Route,
} from "react-router-dom";

export default class PublicContext extends React.Component {
  render() {
    return (
      <React.StrictMode>
        <RouterProvider router={createBrowserRouter([
          {
            path: "*",
            element: <Navigate to="/" replace />,
          },
          {
            path: "/",
            element: <HomePage/>,
          },
          {
            path: "/login",
            element: <LoginPage onLoginSuccess={this.props.onLoginSuccess}/>,
          },
          {
            path: "/register",
            element: <RegisterPage onRegisterSuccess={this.props.onRegisterSuccess}/>,
          },
          {
            path: "/registerWithInvitation/:registrationToken",
            element: <RegisterPage onRegisterSuccess={this.props.onRegisterSuccess}/>,
          },
          {
            path: "/tokenError",
            element: <RegisteredPage tokenError={true}/>,
          },
          {
            path: "/registered",
            element: <RegisteredPage/>,
          },
        ])} />
      </React.StrictMode>
    );
  }
}