
import React, { useState, useEffect } from 'react';
import * as Config from '../../Config'
import {
  Link
} from "react-router-dom";

function LoginPage(props){
  const [formData, setFormData] = useState({email: '', password : '', error : null});
  const handleChange = (event) => {
    setFormData({...formData, [event.target.name] : event.target.value});
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let postData = new FormData();
      postData.append("email", formData.email);
      postData.append("password", formData.password);
      let res = await fetch(Config.BASE_URL + "user/login/", {
        method: "POST",
        body: postData,
      });
      let resJson = await res.json();
      if (res.status === 200 && resJson.success) {
        props.onLoginSuccess(resJson.app_token)
      } else {
        setFormData({...formData, error : (<>Impossibile accedere, verificare le credenziali e riprovare</>)});
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <> 
      <header class="bg-dark border-bottom border-8 border-primary">
        <div class="container d-flex flex-wrap justify-content-center py-5">
          <Link to="/"><span class="fs-2 text-white">TiTurno</span></Link>
        </div>
      </header>
      <div class="container pt-5">
        <div class="row justify-content-around">
          <div class="col-md-8 col-lg-6">
            <h6 class="text-center mb-3">Per continuare accedi a TiTurno</h6>
            <form onSubmit={handleSubmit} class="border-bottom pb-5">
              <div class="form-floating">
                <input type="email" class="form-control" name="email" id="login_email" value={formData.email} onChange={handleChange} required  />
                <label for="login_email">Indirizzo Email</label>
              </div>
              <br/>
              <div class="form-floating">
                <input type="password" class="form-control" name="password" id="login_password" value={formData.password} onChange={handleChange} required  />
                <label for="login_password">Password</label>
              </div>
              <br/>
              { formData.error ? 
                  (<><h6 class="text-danger text-center">{formData.error}</h6><br /></>):(<></>)}
              <div class="container-fluid">
                <div class="row">
                  <div class="col">
                    <Link to="/forgotPassword">Hai dimenticato la password?</Link>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col">
                    <input type='checkbox' disabled></input> Ricordami
                  </div>
                  <button type="submit" class="btn btn-primary col">Accedi</button>
                </div>
              </div>
            </form>
            <div class="text-center mt-5 mb-1">
              Non sei ancora registrato?
            </div>
            <div class="row justify-content-around mb-5">
              <Link to="/register" className='btn btn-outline-dark col-6 mt-2'>Registrati</Link>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}
export default LoginPage;