
import React from 'react';
import * as Config from '../../../Config'

export default class RichiediTurnoModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {email: '', password : ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    var newState = {}
    newState[event.target.name] = event.target.value
    this.setState(newState);
  }

  async handleSubmit(e) {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("email", this.state.email);
      formData.append("password", this.state.password);
      let res = await fetch(Config.BASE_URL + "user/login/", {
        method: "POST",
        body: formData,
      });
      let resJson = await res.json();
      if (res.status === 200 && resJson.success) {
        this.props.onLoginSuccess(resJson.app_token)
        //
      } else {
        
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  render() {
    return (
      <div class="content">
        <form onSubmit={this.handleSubmit}>
          <div class="form-floating">
            <input type="email" class="form-control" name="email" id="login_email" value={this.state.email} onChange={this.handleChange} required  />
            <label for="login_email">Indirizzo Email</label>
          </div>
          <br/>
          <div class="form-floating">
            <input type="password" class="form-control" name="password" id="login_password" value={this.state.password} onChange={this.handleChange} required  />
            <label for="login_password">Password</label>
          </div>
          <br/>
          <div class="container-fluid">
            <div class="row">
              <div class="col"></div>
              <button type="submit" class="btn btn-primary col">Accedi</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}