

import React from 'react';

export const Specs = {
    "1" : "Medico",
    "2" : "Fisioterapista",
    "3" : "Infermiere",
}

export const SpecsOptions = () => {
    return <>{
        Object.keys(Specs).map(element => {
            return (<option value={element} selected>{Specs[element]}</option>)
        })
    }</>
}

export const getRuoloName = (idRuolo) => {
    switch(idRuolo){
        case 20 : 
            return "Caporeparto";
        case 1:
            return "Personale";
    }
    return ""
}