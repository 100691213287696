
import React, { useEffect, useState } from 'react';
import './calendar.css';
import {pSBC} from '../Common'
import * as Config from '../Config'

import Modal from './Modal';
import RichiediTurnoModal from './auth/group_manager/richiediTurnoModal'

import CrudManager from './CrudManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Link
} from "react-router-dom";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Specs } from './form/specsOptions';

export default class Calendar extends React.Component {

    constructor(props) {
      super(props);
      this.scrollContainerRef = React.createRef();
      this.dati = {
        persons : [
            { nome : "Riccardo", cognome:"Rossi", id : 1, spec : 1},
            { nome : "Valerio", cognome:"Rossi", id : 2, spec : 1},
            { nome : "Matilde", cognome:"Bianchi", id : 3, spec : 1},
            { nome : "Giovanni", cognome:"Verdi", id : 4, spec : 2},
            { nome : "Francesca", cognome:"Viola", id : 5, spec : 3},
            { nome : "Federico", cognome:"Blu", id : 6, spec : 3},
            { nome : "Aldo", cognome:"Rossi", id : 1, spec : 1},
            { nome : "Valerio", cognome:"Rossi", id : 1, spec : 1},
            { nome : "Marco", cognome:"Rossi", id : 1, spec : 2},
        ],
        shifts : {
            /**
             * caricato dopo
             */
        }
      }
      var vals = ["M", "P", "N", "S", "F" , " "]
      for(var i = 10; i <= 31; i++){
        for(var y = 1; y <= this.dati.persons.length; y++){
            if(!this.dati.shifts["202211" + i]){
                this.dati.shifts["202211" + i] = {}
            }
            this.dati.shifts["202211" + i][y] = vals[parseInt((Math.random() * vals.length))]
        }
      }
      this.preferences = {
        specsOrder : [3,2,1],
        turniColor : {
          "M" : "#f0f4c3",
          "P" : "#bbdefb",
          "N" : "#b2dfdb",
          "S" : "#f8bbd0",
          "F" : "#ffccbc"
        },
        specsColor : {
            1 : "#bf360c", 2 : "#1b5e20", 3 : "#1a237e", 4 : "#4a148c"
        },
        rowHeight : 40,
        colWidth : 80,
      }
      this.dati.rows = []
      var personsPerSpecMap = {}
      for(var i = 0; i < this.dati.persons.length; i++){
        if(!personsPerSpecMap[this.dati.persons[i].spec]){
            personsPerSpecMap[this.dati.persons[i].spec] = []
        }
        personsPerSpecMap[this.dati.persons[i].spec].push(this.dati.persons[i])
      }
      console.log(personsPerSpecMap)
      this.preferences.specsOrder.forEach(element => {
        personsPerSpecMap[element].sort((a, b) => {
            var cmp = a.cognome.localeCompare(b.cognome)
            if(cmp==0){
                return a.nome.localeCompare(b.nome)
            }
            return cmp
        })
        this.dati.rows.push({t:"qualifica", id : element, nome : Specs[element]})
        personsPerSpecMap[element].forEach(aPerson => {
            aPerson.t = "persona"
            this.dati.rows.push(aPerson)
        })
      });
      this.months = [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre"
      ];
      this.state = {
        disableScroll: false,
        scrollWidth: 0,
        scrollPos: 1,
        clonesWidth: 0,
        modalDate : null,
      }
      this.scrollContainerRef = React.createRef();
      this.handleScroll = this.handleScroll.bind(this);
      this.scrollNext = this.scrollNext.bind(this);
      this.scrollPrev = this.scrollPrev.bind(this);
      this.setScroll = this.setScroll.bind(this);
      this.reCalc = this.reCalc.bind(this);
    }
  
    render() {
      return (
        <div class="container calendar">
          <div class="row" style={{position : "relative"}}>
            <div style={{width:"fit-content"}}>
              <div class="calendarHeader calendarHeaderLabel">Cognome e Nome</div>
              {this.dati.rows.map((value) => {
                  if(value.t == "persona"){
                  return (<div class="calendarBody calendarSideCell" style={{height: this.preferences.rowHeight + "px", lineHeight: this.preferences.rowHeight + "px"}}>
                      {value.cognome} {value.nome}
                  </div>)
                  }else{
                      return (<div class="calendarDividerCell calendarFloatingLabel" style={{backgroundColor:this.preferences.specsColor[value.id]}}>{value.nome}</div>)
                  }
              })}
            </div>
            <div class="calendarControl calendarControlPrev" onMouseDown={this.scrollPrev}><FontAwesomeIcon icon={faChevronLeft}/></div>
            <div class="col calendarRow" ref={this.scrollContainerRef} onScroll={this.handleScroll}>
                {Object.keys(this.dati.shifts).map((date) => this.makeCalendarCol(date))}
            </div>
            <div class="calendarControl calendarControlNext" onMouseDown={this.scrollNext}><FontAwesomeIcon icon={faChevronRight}/></div>
            <div>  debugging values
              <div>ScrollPos:&nbsp;{this.state.scrollPos}</div>
              <div>ScrollWidth:&nbsp;{this.state.scrollWidth}</div>
              <div>modalDate:&nbsp;{this.state.modalDate}</div>
              
            </div>
          </div>
          
          <Modal title="Richiesta Turno" isOpen={this.state.modalDate!=null} onHide={()=>{this.setState({modalDate : null})}}>
            <RichiediTurnoModal date={this.state.modalDate}/>
          </Modal>
        </div>
      )
    }
    
    reCalc() {
      this.scrollContainerRef.current.style.width = null;
      this.scrollContainerRef.current.style.maxWidth = null;
      let scrollPos = this.state.scrollPos;
      let scrollWidth = this.scrollContainerRef.current.clientWidth;
      this.scrollContainerRef.current.style.width = (parseInt(scrollWidth/80)*80) + "px"
      this.scrollContainerRef.current.style.maxWidth = (parseInt(scrollWidth/80)*80) + "px"
  
      if (scrollPos <= 0) {
        scrollPos = 1;
      }
      this.setState({
        scrollPos: scrollPos,
        scrollWidth: scrollWidth
      });
    }
    
    handleScroll(e) {
      const container = e.currentTarget;
      const scrollWidth = container.scrollWidth;
      let scrollPos = container.scrollLeft;
      let scrollPosAdd = 0;
      
      if (!this.state.disableScroll) {
        if (scrollPos + scrollPosAdd >= scrollWidth) {
          this.setScroll(
            // The math floor value helps smooth out the scroll jump, 
            // I don't know why that particular value works, but it does 
            // Same goes for the other setScroll call below
            container, 1 + Math.floor(scrollPosAdd/12.09)
          );
          this.setState({
            disableScroll: true,
          });
        } else if (scrollPos <= 0) {
          this.setScroll(
            container, 0
          );
          this.setState({
            disableScroll: true,
          });
        }
      } 
      
      this.setState({
        scrollWidth: container.scrollWidth,
        scrollPos: container.scrollLeft,
      });
    }
   
    setScroll(element, pos) {
      element.scrollLeft = pos;
      this.setState({
        scrollPos: element.scrollLeft,
      });
    }
    
    scrollNext(e) {
      const container = e.currentTarget.previousSibling;
      e.preventDefault()
      sideScroll(container,'right', 10, this.preferences.colWidth, 10);
    }
    
    scrollPrev(e) {
      const container = e.currentTarget.nextSibling;
      e.preventDefault()
      sideScroll(container, 'left', 10, this.preferences.colWidth, 10);
    }
     
    componentDidUpdate(prevProps, prevState) {
        if (this.state.disableScroll) {
          window.setTimeout(function() {
            this.setState({
              disableScroll: false,
            });
          }.bind(this), 40)
        }
    }
    
    componentDidMount() {
      window.addEventListener('resize', this.reCalc);
      this.reCalc()
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.reCalc);
    }

    onDateSelected(date){
      var tthis = this;
      return ()=>{
        tthis.setState({
          modalDate : date
        })
      }
    }

    makeCalendarCol(date){
      return (<div class="calendarCol" style={{minWidth : this.preferences.colWidth + "px", maxWidth : this.preferences.colWidth + "px"}} onClick={this.onDateSelected(date)}>
        <div class="calendarHeader calendarHeaderDate">
            <span class="day">{date.substring(6,8)}</span>
            <span class="month">{this.months[parseInt(date.substring(4,6))-1]}</span>
            <span class="year">{date.substring(0,4)}</span>
        </div>
        {this.dati.rows.map((value) => {
            if(value.t == "persona"){
                return (<div class="calendarBody calendarShiftCell" style={{
                  height: this.preferences.rowHeight + "px",
                  lineHeight: this.preferences.rowHeight + "px",
                  backgroundColor : pSBC(date%2==0?0.2:0.4, this.preferences.turniColor[this.dati.shifts[date][value.id]])}}>
                    {this.dati.shifts[date][value.id]}
                    </div>)
            }else{
              return (<div class="calendarBody calendarDividerCell" style={{backgroundColor:this.preferences.specsColor[value.id]}}></div>)
            }
        })}
      </div>)
    }
  }

  function sideScroll(element,direction,speed,distance,step) {
    let scrollAmount = 0;
    let startedScrollingFrom = element.scrollLeft
    var slideTimer = setInterval(() => {
        if(direction == 'left'){
            element.scrollLeft -= step;
        } else {
            element.scrollLeft += step;
        }
        scrollAmount += step;
        if(scrollAmount >= distance){
          if(direction == 'left'){
              element.scrollLeft = startedScrollingFrom-distance;
          } else {
            element.scrollLeft = startedScrollingFrom+distance;
          }
          window.clearInterval(slideTimer);
        }
    }, speed);
  } 