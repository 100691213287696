import logo from './logo.svg';
import './App.css';

import PublicContext from './components/public/PublicContext';
import AuthContext from './components/auth/AuthContext';
import LoadUser from './components/public/LoadUser';

import {useLocalStorage} from './Common';
import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom/client";
import moment from 'moment';
import Moment from 'react-moment';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";

Moment.globalLocale = 'it-IT';
Moment.globalLocal = true;

const App = () => {
  const [userToken, setUserToken] = useLocalStorage('user_token', null);
  const [user, setUser] = useState(null);
  
  const doLogout = ()=>{
    setUserToken(null)
    setUser(null)
  }
  if(userToken){
    if(user){
      user.doLogout = doLogout;
      user.existingRolesList = [
        { n :'admin', id : 99 },
        { n :'professional', id : [1,2] },
        { n :'group_manager', id : 20 },
        { n :'director', id : 40 }
      ];
      user.hasCapability = (capability)=>{
        return typeof user.capabilities != 'undefined' && user.capabilities != null
          && (user.capabilities.includes(capability) || user.capabilities.includes("*"));
      }
      user.hasRole = (roleId)=>{
        if(typeof(roleId) === 'number'){
          return user.role == roleId;
        }
        for(var i = 0; i < user.existingRolesList.length; i++){
          let element = user.existingRolesList[i];
          if((typeof(element.id) === 'number' && element.id == user.role) || (typeof(element.id) === 'object' && element.id.includes(user.role))){
            return roleId.toLowerCase() == element.n;
          }
        }
        return false;
      }
      user.getUserToken = userToken
      return (<AuthContext user={user}/>);
    }else{
      return (<LoadUser onUserReady={setUser} onFail={doLogout} getUserToken={userToken}/>)
    }
  }
  return ( <PublicContext onLoginSuccess={setUserToken}/>);
}

export default App;
